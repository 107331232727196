const Controls = {
  forward: "forward",
  back: "back",
  left: "left",
  right: "right",
  jump: "jump",
  ShiftLeft: "shift",
};

export default Controls;
